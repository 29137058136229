




































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ComponentType, PaginatedQueryResult } from '@/models'
import ComponentCreateDialog from '@/components/ComponentCreateDialog.vue'
import * as ComponentTypes from '@/components/componentTypes'
import gql from 'graphql-tag'
import FuzzySearch from 'fuzzy-search'
import Loading from '@/components/Loading.vue'

@Component({
  components: {
    Loading,
    ComponentCreateDialog
  },
  apollo: {
    result: {
      query() {
        return gql`
          query getLists($environmentId: ID) {
            result: ${this.componentTypeName}(environmentId: $environmentId) {
              items ${this.componentType.queryFields}
            }
          }
        `
      },
      variables() {
        return {
          environmentId: this.environmentId
        }
      },
      fetchPolicy: 'network-only'
    }
  }
})
export default class AdminComponentsList extends Vue {
  /** Current Environment ID */
  @Prop({ type: String, required: true }) environmentId!: string
  /** Current component type name */
  @Prop({ type: String, required: true }) componentTypeName!: string

  /** Current search query */
  searchQuery = ''
  showSearchBox = false

  /** Create modal state */
  createModalOpen = false
  drawer: boolean = true

  get bindedStyles() {
    const designListCard: any = {
      backgroundColor: this.$vuetify.theme.currentTheme['background-2'],
      border: `1px solid ${this.$vuetify.theme.currentTheme['background-5']}`,
      height: '100%'
    }

    if (this.$vuetify.breakpoint.mdAndUp) designListCard.width = '100%'

    return {
      designListCard,
      subHeader: {
        borderBottom: `1px solid ${this.$vuetify.theme.currentTheme['background-5']}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1ch 1ch 1ch 2ch'
      },
      componentsDetails: {
        paddingLeft: this.$vuetify.breakpoint.mdAndUp ? '10px' : 0,
        height: 'calc(100vh - 120px)'
      }
    }
  }

  /** Current component type */
  get componentType(): ComponentType<any> {
    let ret = (ComponentTypes as Record<string, ComponentType<any>>)[
      this.componentTypeName
    ]
    return ret
  }

  async handleCreate(component: any) {
    await this.$apollo.queries.result.refetch()
    if (component && component._id) {
      return this.navigateToComponent(component._id)
    }
  }

  async handleDelete() {
    await this.$apollo.queries.result.refetch()
    this.$router.push({ name: 'adminComponentIndex' })
  }

  navigateToComponent(componentId: string) {
    return this.$router.push({
      name: 'adminComponentEdit',
      params: {
        componentId
      }
    })
  }

  // Comenzando a crear las funciones para traer las listas
  result?: PaginatedQueryResult<any>

  get filteredLists() {
    if (!this.result) return []
    let displayResult = this.result.items.map(
      this.componentType.transformResult || ((e) => e)
    )
    const searcher = new FuzzySearch(displayResult || [], [
      'name',
      '_id',
      'title'
    ])
    return searcher.search(this.searchQuery ?? '')
  }
}
